import { request, requestAPI } from '../../../../utils/request';
import {
  claimsRequest,
  claimsSuccess,
  claimsFailure,
  setDisplayClaims,
  updateClaimRequest,
  updateClaimSuccess,
  updateClaimFailure,
  assignableClaimTripsRequest,
  assignableClaimTripsFailure,
  assignableClaimTripsSuccess,
  ClaimsByCarRequest,
  ClaimsByCarSuccess,
  ClaimsByCarFailure,
  setTotalDamages,
  claimRequest,
  claimSuccess,
  claimFailure,
} from './actions';
import message from '../../../../utils/message';
import { setCarDamages } from '../cars';
import { isAfter } from 'date-fns';
import { Dispatch, GetState } from '../../../../@types';
import { ClaimsState } from './initialState';
import { APIFilterParams } from '../../ui/filters';
import { fetchTripInvoiceData } from '../trips';

export const fetchClaims =
  (
    offset: number,
    limit: number,
    successCallback: () => void = () => {},
    filterParams?: APIFilterParams
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    requestAPI('/fleets/damages', {
      limit: limit.toString(),
      offset: offset.toString(),
      ...filterParams,
    })
      .then((pagedClaims) => {
        const updatedClaims = pagedClaims?.damages
          ? [
              ...pagedClaims?.damages.map((claim: any) => ({
                ...claim,
                tripIgnition: isAfter(
                  new Date(claim?.addedAt),
                  new Date(claim?.initialIgnitionOn)
                )
                  ? 'after'
                  : 'before',
              })),
            ]
          : [];
        dispatch(claimsSuccess(updatedClaims));
        dispatch(setDisplayClaims(updatedClaims));
        dispatch(setTotalDamages(pagedClaims?.totalCount));
        if (successCallback && typeof successCallback === 'function') {
          successCallback();
        }
      })
      .catch((err) => {
        dispatch(claimsFailure(err));
        message.error(err.message);
      });
  };

export const fetchClaimsByCarId = (carId?: string) => (dispatch: Dispatch) => {
  dispatch(ClaimsByCarRequest());
  requestAPI(`/fleets/damages/cars/${carId}`)
    .then((damages) => {
      dispatch(setCarDamages(damages));
      dispatch(ClaimsByCarSuccess());
    })
    .catch((err) => {
      dispatch(ClaimsByCarFailure());
      message.error(err.message);
    });
};

const updateClaim =
  (
    token: string,
    claimId: string,
    carId: string,
    claimData: any,
    successCallback: (arg: any) => void
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateClaimRequest());
    requestAPI(
      `/fleets/damages/${carId}/${claimId}`,
      {},
      {
        method: 'PUT',
        body: JSON.stringify(claimData),
      }
    )
      .then((data) => {
        successCallback(data);
        dispatch(updateClaimSuccess(data));
        message.success('Updated');
      })
      .catch((err) => {
        dispatch(updateClaimFailure(err));
        message.error(err.message);
      });
  };

const shouldUpdateClaim = (state: ClaimsState) => !state.updatingStatus;

export const updateClaimIfNeeded = (
  token: string,
  claimId: string,
  carId: string,
  claimData: any,
  successCallback: (data: any) => void = () => {}
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldUpdateClaim(getState().userData.claims)) {
      return dispatch(
        updateClaim(token, claimId, carId, claimData, successCallback)
      );
    } else {
      return Promise.resolve();
    }
  };
};

export const fetchAssignableClaimTrips =
  (carId: string, beforeTripId: string) => (dispatch: Dispatch) => {
    dispatch(assignableClaimTripsRequest());
    const beforeIdObj = beforeTripId
      ? { beforeId: beforeTripId.toString() }
      : {};
    requestAPI(`/fleets/cars/${carId}/trips`, beforeIdObj)
      .then((trips) => {
        dispatch(assignableClaimTripsSuccess(trips));
      })
      .catch((err) => {
        dispatch(assignableClaimTripsFailure(err));
        message.error(err.message);
      });
  };

export const fetchClaimById =
  (claimId: string | null) => (dispatch: Dispatch) => {
    dispatch(claimRequest());
    requestAPI(`/fleets/damages/${claimId}`)
      .then((claim) => {
        if (claim.ok === undefined) {
          dispatch(claimSuccess(claim));
          if (claim?.carId) {
            dispatch(fetchAssignableClaimTrips(claim?.carId, claim.tripId));
          }
          if (claim?.assignedTripId) {
            dispatch(fetchTripInvoiceData(claim.assignedTripId));
          }
        }
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(claimFailure(err));
      });
  };
