import { requestAPI, updateAPI } from '../../../../utils/request';
import {
  privacyPoliciesRequest,
  privacyPoliciesSuccess,
  privacyPoliciesFailure,
  setDisplayPrivacyPolicies,
  privacyPolicyUpdateRequest,
  privacyPolicyUpdateSuccess,
  privacyPolicyUpdateFailure,
  privacyPolicyRequest,
  privacyPolicySuccess,
  privacyPolicyFailure,
  purposeSuccess,
  purposeUpdateRequest,
  purposeUpdateSuccess,
  purposeUpdateFailure,
  copyPrivacyPolicySuccess,
  activationEventsRequest,
  activationEventsSuccess,
  activationEventsFailure,
  groupsRequest,
  groupsSuccess,
  groupsFailure,
  createPurposeSuccess,
  archivePurposeSuccess,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { PricingState } from '../../../../@types/enums';

const fetchPrivacyPolicies = () => (dispatch: Dispatch) => {
  dispatch(privacyPoliciesRequest());
  requestAPI('/fleets/privacy/policies')
    .then((privacyPolicies) => {
      dispatch(privacyPoliciesSuccess(privacyPolicies));
      dispatch(setDisplayPrivacyPolicies(privacyPolicies));
    })
    .catch((err) => {
      dispatch(privacyPoliciesFailure(err));
      message.error(err.message);
    });
};

const shouldFetchPrivacyPolicies = (state: PricingState) => !state.loading;

export const fetchPrivacyPoliciesIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchPrivacyPolicies(getState().appData.privacyPolicies)) {
      return dispatch(fetchPrivacyPolicies());
    } else {
      return Promise.resolve();
    }
  };
};

const fetchActivationEvents = () => (dispatch: Dispatch) => {
  dispatch(activationEventsRequest());
  requestAPI('/fleets/privacy/activationEvents')
    .then((activationEvents) => {
      dispatch(activationEventsSuccess(activationEvents));
    })
    .catch((err) => {
      dispatch(activationEventsFailure(err));
      message.error(err.message);
    });
};

const shouldFetchActivationEvents = (state: PricingState) =>
  !state?.activationEvents;

export const fetchActivationEventsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchActivationEvents(getState().appData.privacyPolicies)) {
      return dispatch(fetchActivationEvents());
    } else {
      return Promise.resolve();
    }
  };
};

const fetchGroups = () => (dispatch: Dispatch) => {
  dispatch(groupsRequest());
  requestAPI('/fleets/privacy/fieldGroups')
    .then((activationEvents) => {
      dispatch(groupsSuccess(activationEvents));
    })
    .catch((err) => {
      dispatch(groupsFailure(err));
      message.error(err.message);
    });
};

const shouldFetchGroups = (state: PricingState) => !state?.groups;

export const fetchGroupsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchGroups(getState().appData.privacyPolicies)) {
      return dispatch(fetchGroups());
    } else {
      return Promise.resolve();
    }
  };
};

export const fetchPrivacyPolicyById = (id: string) => (dispatch: Dispatch) => {
  dispatch(privacyPolicyRequest());

  requestAPI(`/fleets/privacy/policies/${id}`)
    .then((res) => {
      dispatch(privacyPolicySuccess(res));
    })
    .catch((err) => {
      dispatch(privacyPolicyFailure(err));
      message.error(err.message);
    });
};

export const fetchPurposeById = (id: string) => (dispatch: Dispatch) => {
  requestAPI(`/fleets/privacy/processingPurposes/${id}`)
    .then((res) => {
      dispatch(purposeSuccess(res));
    })
    .catch((err) => {
      message.error(err.message);
    });
};

export const publishPrivacyPolicy = (id: string) => (dispatch: Dispatch) => {
  dispatch(privacyPolicyRequest());

  requestAPI(`/fleets/privacy/policies/${id}/publish`, {}, { method: 'PUT' })
    .then((res) => {
      if (res?.ok === undefined) {
        dispatch(privacyPolicySuccess(res));
        message.success('Published!');
      }
    })
    .catch((err) => {
      dispatch(privacyPolicyFailure(err));
      message.error(err.message);
    });
};

export const archivePrivacyPolicy = (id: string) => (dispatch: Dispatch) => {
  dispatch(privacyPolicyUpdateRequest());

  requestAPI(`/fleets/privacy/policies/${id}/archive`, {}, { method: 'PUT' })
    .then((res) => {
      if (res?.ok === undefined) {
        dispatch(privacyPolicyUpdateSuccess(res));
        message.success('Archived!');
      } else {
        message.error('Error');
      }
    })
    .catch((err) => {
      dispatch(privacyPolicyUpdateFailure(err));
      message.error(err.message);
    });
};

export const copyPrivacyPolicy = (id: string) => (dispatch: Dispatch) => {
  dispatch(privacyPolicyRequest());

  requestAPI(`/fleets/privacy/policies/${id}/copy`, {}, { method: 'POST' })
    .then((res) => {
      if (res?.ok === undefined) {
        dispatch(copyPrivacyPolicySuccess(res));
        message.success('Copied!');
      }
    })
    .catch((err) => {
      dispatch(privacyPolicyFailure(err));
      message.error(err.message);
    });
};

export const archivePurpose = (purposeId: string) => (dispatch: Dispatch) => {
  requestAPI(
    `/fleets/privacy/processingPurposes/${purposeId}/archive`,
    {},
    { method: 'PUT' }
  )
    .then((res) => {
      if (res?.ok === undefined) {
        dispatch(archivePurposeSuccess({ purposeId }));
        message.success('Archived!');
      }
    })
    .catch((err) => {
      message.error(err.message);
    });
};

export const updatePurpose = updateAPI(
  purposeUpdateRequest,
  purposeUpdateSuccess,
  purposeUpdateFailure,
  '/fleets/privacy/processingPurposes/'
);

export const createPurpose = (purposePayload: any) => (dispatch: Dispatch) => {
  requestAPI(
    `/fleets/privacy/processingPurposes`,
    {},
    { method: 'POST', body: JSON.stringify(purposePayload) }
  )
    .then((res) => {
      if (res?.ok === undefined) {
        dispatch(createPurposeSuccess(res));
        message.success('Created!');
      }
    })
    .catch((err) => {
      message.error(err.message);
    });
};
