import { Reducer } from '../../../../@types';
import { PrivacyPolicyTypes } from '../../../../@types/enums';
import { initialState, PricingsState } from './initialState';

// @ts-ignore
export const privacyPoliciesReducer: Reducer<PricingsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case PrivacyPolicyTypes.PRIVACY_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PrivacyPolicyTypes.PRIVACY_POLICIES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case PrivacyPolicyTypes.PRIVACY_POLICIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case PrivacyPolicyTypes.ACTIVATION_EVENTS_SUCCESS:
      return {
        ...state,
        activationEvents: action.payload,
        loading: false,
      };
    case PrivacyPolicyTypes.GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };
    case PrivacyPolicyTypes.PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              ...state.entities!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        display: state?.display
          ? [
              ...state.display!.map((entity) =>
                entity.id === action.payload.id ? action.payload : entity
              ),
            ]
          : null,
        selected: action.payload,
        selectedPurpose: null,
      };
    case PrivacyPolicyTypes.PURPOSE_SUCCESS:
      return {
        ...state,
        selected: null,
        selectedPurpose: action.payload,
      };
    case PrivacyPolicyTypes.SELECT_PRIVACY_POLICY:
      return {
        ...state,
        selected: action?.payload,
      };
    case PrivacyPolicyTypes.SELECT_PURPOSE:
      return {
        ...state,
        selectedPurpose: action?.payload,
      };
    case PrivacyPolicyTypes.SET_DISPLAY_PRIVACY_POLICY:
      return {
        ...state,
        display: action?.payload,
      };
    case PrivacyPolicyTypes.PRIVACY_POLICY_UPDATE_SUCCESS: {
      const newItem = {
        ...action?.payload,
      };
      return {
        ...state,
        entities: state?.entities?.map((item) => {
          if (item.id === newItem.id) {
            return newItem;
          }
          return item;
        }),
        display: state?.display?.map((item) => {
          if (
            item.id === newItem.id &&
            // @ts-ignore
            item?.version === newItem?.version
          ) {
            return newItem;
          }
          return item;
        }),
        selected: newItem,
        selectedPurpose: null,
      };
    }
    case PrivacyPolicyTypes.PURPOSE_UPDATE_SUCCESS: {
      const updatedPurpose = { ...action.payload };

      const updatePurposes = (policies) =>
        policies?.map((policy) => ({
          ...policy,
          processingPurposes: policy?.processingPurposes?.map((purpose) =>
            purpose?.id === updatedPurpose?.id ? updatedPurpose : purpose
          ),
        }));

      return {
        ...state,
        entities: updatePurposes(state?.entities),
        display: updatePurposes(state?.display),
        selected: null,
        selectedPurpose: updatedPurpose,
      };
    }

    case PrivacyPolicyTypes.COPY_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        entities: [action.payload, ...(state?.entities || [])],
        display: [action.payload, ...(state?.display || [])],
        selected: action.payload,
      };

    case PrivacyPolicyTypes.PRIVACY_POLICY_DELETE_SUCCESS:
      return {
        ...state,
        entities: state?.entities?.filter(
          (item) => !(item.id === action.payload.itemId)
        ),
        display: state?.display?.filter(
          (item) => !(item.id === action.payload.itemId)
        ),
        selected: null,
      };

    case PrivacyPolicyTypes.ARCHIVE_PURPOSE_SUCCESS: {
      const updatedPurpose = { ...action.payload };

      const archivePurposes = (policies) =>
        policies?.map((policy) => ({
          ...policy,
          processingPurposes: policy?.processingPurposes?.filter(
            (purpose) => purpose?.id !== updatedPurpose?.purposeId
          ),
        }));

      return {
        ...state,
        entities: archivePurposes(state?.entities),
        display: archivePurposes(state?.display),
        selected: null,
        selectedPurpose: null,
      };
    }

    case PrivacyPolicyTypes.CREATE_PURPOSE_SUCCESS: {
      const updatedPurpose = { ...action.payload };

      const addPurposes = (policies) =>
        policies?.map((policy) => ({
          ...policy,
          processingPurposes:
            policy.id === updatedPurpose.policyId
              ? [updatedPurpose, ...policy.processingPurposes]
              : policy.processingPurposes,
        }));

      return {
        ...state,
        entities: addPurposes(state?.entities),
        display: addPurposes(state?.display),
        selected: null,
        selectedPurpose: updatedPurpose,
      };
    }
    default:
      return state;
  }
};
