import React from 'react';
import { Collapse, Tag } from 'antd';
import ListEditItem from '../../../shared/ListEditItem';
import ListItem from '../../../shared/ListItem';
import LinkToReceipt from '../../../shared/LinkToReceipt';
import LoadingIcon from '../../../shared/LoadingIcon';
import { formatPrice, getPaymentIcon, getStatusColor } from '../../../../utils';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import { renderDate } from '../../../shared/TableLayout';
import styles from '../UserInfo.styles';
import { PaymentTypeSwitch } from './PaymentTypeSwitch';

const { Panel } = Collapse;

interface UserPaymentsInfoProps {
  selectedUser: any;
  intl: any;
  isReceiptLoading: boolean;
  onEditPayment: () => void;
  onClickSetDefaultPayment: (id: string) => void;
  onClickDeletePayment: (id: string) => void;
  onClickCancelDeposit: () => void;
  onCreditWorthinessUpdate: () => void;
  allowedPaymentTypes?: (
    | 'card'
    | 'sepa'
    | 'payIn'
    | 'manual'
    | 'paypal'
    | 'marketPayManualPayout'
  )[];
  depositEnabled: boolean;
}

const UserPaymentsInfo: React.FC<UserPaymentsInfoProps> = ({
  selectedUser,
  intl,
  isReceiptLoading,
  onEditPayment,
  onClickSetDefaultPayment,
  onClickDeletePayment,
  onClickCancelDeposit,
  onCreditWorthinessUpdate,
  allowedPaymentTypes,
  depositEnabled,
}) => {
  const getPaymentHeader = (payment: any) => {
    const paymentPreTitle =
      payment.methodType === 'payIn' || payment.methodType === 'paypal'
        ? intl.formatMessage({
            id: `fleetadmin.users.info.${payment.methodType}`,
          })
        : payment.methodType;

    const paymentTitle = payment?.sepa?.iban
      ? payment.status === 'verified'
        ? `: ${payment.sepa.iban}`
        : `: ****${payment?.sepa?.iban?.substr(-4)}`
      : payment?.card?.number
      ? `: ****${payment?.card?.number?.substr(-4)}`
      : '';

    return (
      <>
        <div className="payment-header">
          <span>
            <img
              src={getPaymentIcon(payment)}
              className={
                payment.methodType === 'payIn'
                  ? 'payment-icon-payIn'
                  : payment.methodType === 'paypal'
                  ? 'payment-icon-paypal'
                  : 'payment-icon'
              }
            />
            {`${paymentPreTitle}${paymentTitle}`}
          </span>
          {payment.defaultOne && (
            <Tag color={getStatusColor('primary')}>
              {intl.formatMessage({ id: `fleetadmin.status.primary` })}
            </Tag>
          )}
        </div>
        <style jsx>{styles}</style>
      </>
    );
  };

  const getWorthinessHeader = (creditworthinessData) => {
    return (
      <div className="dl-header">
        <span>
          {`${intl.formatMessage({
            id: `fleetadmin.users.info.creditworthinessData`,
          })}`}
        </span>
        {creditworthinessData?.creditWorthinessStatus && (
          <Tag
            color={getStatusColor(creditworthinessData.creditWorthinessStatus)}
          >
            {intl.formatMessage({
              id: `fleetadmin.status.${creditworthinessData.creditWorthinessStatus}`,
            })}
          </Tag>
        )}
        <style jsx>{styles}</style>
      </div>
    );
  };

  const getDepositHeader = (depositBalance: number) => {
    const depositStatus = depositBalance > 0 ? 'done' : 'open';
    return (
      <div className="dl-header">
        <span>
          {intl.formatMessage({ id: `fleetadmin.users.info.deposit` })}
        </span>
        <Tag color={getStatusColor(depositStatus)}>
          {intl.formatMessage({ id: `fleetadmin.status.${depositStatus}` })}
        </Tag>
        <style jsx>{styles}</style>
      </div>
    );
  };

  return (
    <div key={selectedUser?.id}>
      <div className="user-section-title">
        {intl.formatMessage({ id: `fleetadmin.users.info.payment.settings` })}
        <img
          className="edit-button"
          src="/images/drawer/edit.svg"
          alt="edit"
          onClick={onEditPayment}
        />
      </div>
      {allowedPaymentTypes?.map((paymentType) => {
        const isSelected =
          !selectedUser?.blockedPaymentTypes?.includes(paymentType);
        return (
          <PaymentTypeSwitch
            key={paymentType}
            paymentType={paymentType}
            isSelected={isSelected}
            isDisabled={true}
          />
        );
      })}
      <div className="collapse-container">
        <Collapse bordered={false}>
          <Panel
            header={getWorthinessHeader(selectedUser?.creditworthinessData)}
            key={1}
          >
            {selectedUser?.creditworthinessData?.creditWorthinessStatus && (
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.payment.status`,
                })}
                leftItem={renderText(
                  selectedUser?.creditworthinessData?.creditWorthinessStatus,
                  false
                )}
                onClickEdit={onCreditWorthinessUpdate}
              />
            )}
            {selectedUser?.creditworthinessData?.rejectionReason &&
              selectedUser?.creditworthinessData?.rejectionReason !==
                selectedUser?.creditworthinessData?.score && (
                <ListItem
                  label={intl.formatMessage({
                    id: `fleetadmin.users.info.cw.reason`,
                  })}
                  value={renderText(
                    selectedUser?.creditworthinessData?.rejectionReason,
                    false
                  )}
                />
              )}

            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.address`,
              })}
              leftItem={renderText(
                selectedUser?.creditworthinessData?.formattedAddress,
                true
              )}
            />

            {selectedUser?.creditworthinessData?.score && (
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.creditworthiness.score`,
                })}
                leftItem={renderText(
                  selectedUser?.creditworthinessData?.score,
                  false
                )}
              />
            )}
            {selectedUser?.creditworthinessData?.lastStatusChangedAt && (
              <ListItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.payment.lastchangeat`,
                })}
                value={renderDate(
                  selectedUser?.creditworthinessData?.lastStatusChangedAt,
                  false
                )}
              />
            )}
            {selectedUser?.creditworthinessData?.consentGivenAt && (
              <ListItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.payment.consentgivenat`,
                })}
                value={renderDate(
                  selectedUser?.creditworthinessData?.consentGivenAt,
                  false
                )}
              />
            )}
            {selectedUser?.creditworthinessData?.lastStatusChangedBy && (
              <ListItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.payment.lastchangeby`,
                })}
                value={renderText(
                  selectedUser?.creditworthinessData?.lastStatusChangedBy,
                  false
                )}
              />
            )}

            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.cw.dateofbirth`,
              })}
              value={renderText(
                selectedUser?.creditworthinessData?.dateOfBirth,
                true
              )}
            />

            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.cw.placeofbirth`,
              })}
              value={renderText(
                selectedUser?.creditworthinessData?.placeOfBirth,
                true
              )}
            />
          </Panel>
        </Collapse>
      </div>
      {depositEnabled && (
        <div className="collapse-container">
          <Collapse bordered={false}>
            <Panel
              header={getDepositHeader(selectedUser?.deposit?.balance)}
              key="deposit"
            >
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.deposit.status`,
                })}
                leftItem={renderText(
                  intl.formatMessage({
                    id: `fleetadmin.status.${
                      selectedUser?.deposit?.balance > 0 ? 'done' : 'open'
                    }`,
                  }),
                  false
                )}
              />
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.deposit.balance`,
                })}
                leftItem={renderText(
                  selectedUser?.deposit?.balance > 0
                    ? formatPrice(selectedUser?.deposit?.balance)
                    : '',
                  true
                )}
              />
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.deposit.receipt`,
                })}
                leftItem={
                  selectedUser?.deposit?.receiptNo ? (
                    <LinkToReceipt
                      receiptNo={selectedUser?.deposit?.receiptNo}
                      date={selectedUser?.deposit?.paidAt}
                    />
                  ) : (
                    '-'
                  )
                }
                rightItem={isReceiptLoading ? <LoadingIcon /> : null}
              />
              {selectedUser?.deposit?.balance > 0 && (
                <div
                  className="collapse-red-action"
                  onClick={onClickCancelDeposit}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.deposit.cancel`,
                  })}
                </div>
              )}
            </Panel>
          </Collapse>
        </div>
      )}

      <div className="user-section-title">
        {intl.formatMessage({ id: `fleetadmin.users.info.payment.profiles` })}
      </div>
      {selectedUser?.paymentMethods?.length === 0 ? (
        <div className="empty-wrapper">
          <img
            className="empty"
            src="/images/table/empty.svg"
            width="50"
            height="50"
          />
          {intl.formatMessage({ id: `fleetadmin.users.info.empty` })}
        </div>
      ) : (
        <div className="collapse-container">
          <Collapse bordered={false}>
            {selectedUser?.paymentMethods?.map(
              (payment: any, index: number) => (
                <Panel header={getPaymentHeader(payment)} key={index}>
                  <ListEditItem
                    label={intl.formatMessage({
                      id: `fleetadmin.users.info.payment.status`,
                    })}
                    leftItem={renderText(payment.status, false)}
                  />
                  {payment?.lastChangedAt && (
                    <ListItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.info.payment.lastchanged`,
                      })}
                      value={renderDate(payment.lastChangedAt, false)}
                    />
                  )}

                  {payment?.sepa?.accountHolder && (
                    <ListItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.info.payment.info`,
                      })}
                      value={renderText(payment?.sepa?.accountHolder, true)}
                    />
                  )}
                  {payment?.sepa?.bic && (
                    <ListItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.payment.bic`,
                      })}
                      value={renderText(payment?.sepa?.bic, true)}
                    />
                  )}

                  <ListItem
                    label={intl.formatMessage({
                      id: `fleetadmin.users.info.payment.type`,
                    })}
                    value={renderText(
                      payment?.billingInfo?.billingProfile,
                      false
                    )}
                  />
                  {selectedUser?.paymentMethods?.length > 1 && (
                    <div
                      className="collapse-blue-action"
                      onClick={() => onClickSetDefaultPayment(payment.id)}
                    >
                      {intl.formatMessage({
                        id: `fleetadmin.users.info.payment.set.default`,
                      })}
                    </div>
                  )}
                  <div
                    className="collapse-unpair"
                    onClick={() => onClickDeletePayment(payment.id)}
                  >
                    {intl.formatMessage({
                      id: `fleetadmin.users.info.payment.remove`,
                    })}
                  </div>
                </Panel>
              )
            )}
          </Collapse>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default UserPaymentsInfo;
