import React, { MouseEvent } from 'react';
import packageInfo from '../../package.json';
import cn from 'classnames';
import { Button, Modal, Popover } from 'antd';
import { batch, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { setQueries } from '../libs/setQueries';
import { ViewMode } from '../hooks/useViewMode';
import { ViewType } from '../hooks/useViewType';
import styles from './SideMenu.styles';
import { selectArea } from '../redux/store/appData/areas';
import { selectClaim } from '../redux/store/userData/claims';
import { selectCare } from '../redux/store/userData/cares';
import {
  fetchCarsIfNeeded,
  selectCar,
  selectSelectedVehiclesSubmenu,
  selectVehiclesSubmenu,
} from '../redux/store/userData/cars';
import { selectBooking, selectTrip } from '../redux/store/userData/trips';
import isDesktop from '../utils/sizeHelpers';
import redirectToPath from '../utils/redirectToPath';
import {
  openModal,
  setDisplayColumns,
  selectViewMode,
} from '../redux/store/ui/common';
import { selectFilter } from '../redux/store/ui/filters';
import { selectAuth } from '../redux/store/userData/user';
import { selectCategory } from '../redux/store/userData/categories';
import { selectPricing } from '../redux/store/appData/pricings';
import { selectCircle } from '../redux/store/userData/circles';
import { selectProfile } from '../redux/store/userData/profiles';
import { defaultSelectedReceiptsColumns } from './ColumnSelector/receiptsColumns';
import { defaultSelectedPrivacyPolicyColumns } from './ColumnSelector/privacyPolicyColumns';

const FLEET_PRO_LOGO = `/images/logo${
  process.env.REACT_APP_STAGE === 'staging' ? '-staging' : ''
}.png`;

const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(selectAuth);
  const viewMode = useSelector(selectViewMode);
  const filter = useSelector(selectFilter);
  const { email } = useSelector(selectAuth) as any;
  const selectedVehiclesSubmenu = useSelector(selectSelectedVehiclesSubmenu);
  const isGetawayAdmin =
    email && (email.includes('get-a-way') || email.includes('getaway'));

  const handleOnClickLogout = (e: MouseEvent) => {
    e.preventDefault();
    if (!isDesktop()) {
      closeNav();
    }
    Modal.confirm({
      className: 'logoutModal',
      title: intl.formatMessage({ id: 'fleetadmin.logoutConfirm.title' }),
      content: intl.formatMessage({ id: 'fleetadmin.logoutConfirm.text' }),
      onOk() {
        window.localStorage.clear();
        redirectToPath('/login.html');
      },
      onCancel() {},
    });
  };

  const updateQuery = (vm: ViewMode) => {
    // @ts-ignore
    const filters = filter[vm];
    const queries = {
      vm,
      type: ViewType.TABLE,
      ...filters,
    };
    setQueries(queries, true);
  };

  const unselectEntity = () => {
    batch(() => {
      dispatch(selectCar(null));
      dispatch(selectArea(null));
      dispatch(selectCare(null));
      dispatch(selectTrip(null));
      dispatch(selectBooking(null));
      dispatch(selectClaim(null));
      dispatch(selectCategory(null));
      dispatch(selectPricing(null));
      dispatch(selectCircle(null));
      dispatch(selectProfile(null));
      dispatch(selectVehiclesSubmenu(null));
    });
  };

  const onClickAllVehicles = () => {
    unselectEntity();
    dispatch(fetchCarsIfNeeded());
    updateQuery(ViewMode.VEHICLE);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickArchivedVehicles = () => {
    unselectEntity();
    dispatch(fetchCarsIfNeeded(true));
    updateQuery(ViewMode.VEHICLE);
    dispatch(selectVehiclesSubmenu('archived'));
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickProfile = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.PROFILE);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickArea = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.AREA);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickUsers = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.USERS);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickAdmins = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.ADMINS);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickReceipts = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.RECEIPTS);
    if (!isDesktop()) {
      closeNav();
    }
    dispatch(
      setDisplayColumns({ receipts: [...defaultSelectedReceiptsColumns] })
    );
  };

  const onClickClaim = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.CLAIM);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickCare = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.CARE);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickTrips = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.SHARE);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickBookings = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.BOOKING);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickCategories = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.CATEGORIES);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickPricing = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.PRICING);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onClickPrivacyPolicy = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.PRIVACY_POLICY);
    if (!isDesktop()) {
      closeNav();
    }
    dispatch(
      setDisplayColumns({
        privacyPolicy: [...defaultSelectedPrivacyPolicyColumns],
      })
    );
  };

  const onClickCircles = (e: MouseEvent) => {
    e.preventDefault();
    unselectEntity();
    updateQuery(ViewMode.CIRCLE);
    if (!isDesktop()) {
      closeNav();
    }
  };

  const onPressPhoneReportsTab = () => {
    dispatch(openModal('phoneReportsModal'));
    if (!isDesktop()) {
      closeNav();
    }
  };

  const closeNav = () => {
    // @ts-ignore
    document.getElementById('sidebar').style.width = '0';
    // @ts-ignore
    document.getElementById('main').style.marginLeft = '0';
    // @ts-ignore
    document.getElementById('main-header').style.left = '0';
  };

  return (
    <>
      <div id="sidebar" className="side-menu">
        <div className="side-menu-header-logo-wrapper">
          <div className="closebtn" onClick={closeNav}>
            ×
          </div>
          <img
            className="side-menu-header-logo"
            src={FLEET_PRO_LOGO}
            alt="fleet pro"
          />
        </div>
        <div className="menu">
          <ul className="menu-items">
            {(user?.permissions?.fleetManager ||
              user?.permissions?.claimsManager) && (
              <li className="menu-item section-name">
                <FormattedMessage id="fleetadmin.sideMenu.fleetControl" />
              </li>
            )}
            {user?.permissions?.fleetManager && (
              <>
                <li className={cn('menu-item', 'menu-item-title')}>
                  <FormattedMessage id="fleetadmin.sideMenu.vehicle" />
                </li>
                <li
                  className={cn('menu-item', 'sub-menu', {
                    active:
                      viewMode === ViewMode.VEHICLE &&
                      selectedVehiclesSubmenu !== 'archived',
                  })}
                  onClick={onClickAllVehicles}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.vehicle.all" />
                </li>
                <li
                  className={cn('menu-item', 'sub-menu', {
                    active:
                      viewMode === ViewMode.VEHICLE &&
                      selectedVehiclesSubmenu === 'archived',
                  })}
                  onClick={onClickArchivedVehicles}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.vehicle.archived" />
                </li>
                <li
                  className={cn('menu-item', {
                    active: viewMode === ViewMode.AREA,
                  })}
                  onClick={onClickArea}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.area" />
                </li>
              </>
            )}
            {user?.permissions?.claimsManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.CLAIM,
                })}
                onClick={onClickClaim}
              >
                <FormattedMessage id="fleetadmin.sideMenu.claim" />
              </li>
            )}
            {(user?.permissions?.userGroupsManager ||
              user?.permissions?.userManager ||
              user?.permissions?.merchantManager) && (
              <>
                <li className="menu-item section-name">
                  <FormattedMessage id="fleetadmin.sideMenu.userControl" />
                </li>
                {user?.permissions?.userManager && (
                  <li
                    className={cn('menu-item', {
                      active: viewMode === ViewMode.USERS,
                    })}
                    onClick={onClickUsers}
                  >
                    <FormattedMessage id="fleetadmin.sideMenu.users" />
                  </li>
                )}
                {user?.permissions?.userGroupsManager && (
                  <li
                    className={cn('menu-item', {
                      active: viewMode === ViewMode.CIRCLE,
                    })}
                    onClick={onClickCircles}
                  >
                    <FormattedMessage id="fleetadmin.sideMenu.fleetCircles" />
                  </li>
                )}
              </>
            )}
            {user?.permissions?.tripsManager && (
              <>
                <li className="menu-item section-name">
                  <FormattedMessage id="fleetadmin.sideMenu.capabilities" />
                </li>
                <li className={cn('menu-item', 'menu-item-title')}>
                  <FormattedMessage id="fleetadmin.sideMenu.fleetShare" />
                </li>
                <li
                  className={cn('menu-item', 'sub-menu', {
                    active: viewMode === ViewMode.SHARE,
                  })}
                  onClick={onClickTrips}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.fleetShare.trips" />
                </li>
                <li
                  className={cn('menu-item', 'sub-menu', {
                    active: viewMode === ViewMode.BOOKING,
                  })}
                  onClick={onClickBookings}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.fleetShare.bookings" />
                </li>

                <li
                  className={cn('menu-item', {
                    active: viewMode === ViewMode.CARE,
                  })}
                  onClick={onClickCare}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.fleetCare" />
                </li>
              </>
            )}
            {user?.permissions?.paymentManager && (
              <>
                <li className="menu-item section-name">
                  <FormattedMessage id="fleetadmin.sideMenu.payments" />
                </li>
                <li
                  className={cn('menu-item', {
                    active: viewMode === ViewMode.RECEIPTS,
                  })}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={onClickReceipts}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.receipts" />
                </li>
              </>
            )}
            {(user?.permissions?.merchantManager ||
              user?.permissions?.fleetManager) && (
              <li className="menu-item section-name">
                <FormattedMessage id="fleetadmin.sideMenu.fleetSettings" />
              </li>
            )}
            {user?.permissions?.merchantManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.ADMINS,
                })}
                onClick={onClickAdmins}
              >
                <FormattedMessage id="fleetadmin.sideMenu.admins" />
              </li>
            )}
            {user?.permissions?.fleetManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.CATEGORIES,
                })}
                onClick={onClickCategories}
              >
                <FormattedMessage id="fleetadmin.sideMenu.fleetCategories" />
              </li>
            )}
            {user?.permissions?.fleetManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.PROFILE,
                })}
                onClick={onClickProfile}
              >
                <FormattedMessage id="fleetadmin.sideMenu.fleetProfiles" />
              </li>
            )}
            {user?.permissions?.fleetManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.PRICING,
                })}
                onClick={onClickPricing}
              >
                <FormattedMessage id="fleetadmin.sideMenu.fleetPricing" />
              </li>
            )}
            {user?.permissions?.privacyPolicyManager && (
              <li
                className={cn('menu-item', {
                  active: viewMode === ViewMode.PRIVACY_POLICY,
                })}
                onClick={onClickPrivacyPolicy}
              >
                <FormattedMessage id="fleetadmin.sideMenu.privacy.policy" />
              </li>
            )}
            {isGetawayAdmin && (
              <>
                <li className="menu-item section-name">
                  <FormattedMessage id="fleetadmin.sideMenu.reports" />
                </li>
                <li
                  className={cn('menu-item')}
                  onClick={onPressPhoneReportsTab}
                >
                  <FormattedMessage id="fleetadmin.sideMenu.phoneReport" />
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="footer">
          <Popover
            content={
              <div>
                <p>
                  {user.firstName} {user.lastName}
                </p>
                <p>{user.email}</p>
                <Button onClick={handleOnClickLogout}>
                  <FormattedMessage id="fleetadmin.sideMenu.logout" />
                </Button>
              </div>
            }
            trigger="hover"
            placement="topRight"
          >
            <p className="username">
              {user.firstName} {user.lastName}
            </p>
          </Popover>
        </div>
        <p className="version">{`v${packageInfo.version} - ${process?.env?.REACT_APP_COMMIT_HASH}`}</p>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default SideMenu;
