export type PrivacyPolicyState = any;

export enum PrivacyPolicyTypes {
  PRIVACY_POLICIES_REQUEST = 'appData/privacy/PRIVACY_POLICIES_REQUEST',
  PRIVACY_POLICIES_SUCCESS = 'appData/privacy/PRIVACY_POLICIES_SUCCESS',
  PRIVACY_POLICIES_FAILURE = 'appData/privacy/PRIVACY_POLICIES_FAILURE',
  ACTIVATION_EVENTS_REQUEST = 'appData/privacy/ACTIVATION_EVENTS_REQUEST',
  ACTIVATION_EVENTS_SUCCESS = 'appData/privacy/ACTIVATION_EVENTS_SUCCESS',
  ACTIVATION_EVENTS_FAILURE = 'appData/privacy/ACTIVATION_EVENTS_FAILURE',
  GROUPS_REQUEST = 'appData/privacy/GROUPS_REQUEST',
  GROUPS_SUCCESS = 'appData/privacy/GROUPS_SUCCESS',
  GROUPS_FAILURE = 'appData/privacy/GROUPS_FAILURE',
  PURPOSE_SUCCESS = 'appData/privacy/PURPOSE_SUCCESS',
  PRIVACY_POLICY_REQUEST = 'appData/privacy/PRIVACY_POLICY_REQUEST',
  PRIVACY_POLICY_SUCCESS = 'appData/privacy/PRIVACY_POLICY_SUCCESS',
  PRIVACY_POLICY_FAILURE = 'appData/privacy/PRIVACY_POLICY_FAILURE',
  COPY_PRIVACY_POLICY_SUCCESS = 'appData/privacy/COPY_PRIVACY_POLICY_SUCCESS',
  CREATE_PURPOSE_SUCCESS = 'appData/privacy/CREATE_PURPOSE_SUCCESS',
  ARCHIVE_PURPOSE_SUCCESS = 'appData/privacy/ARCHIVE_PURPOSE_SUCCESS',
  SET_DISPLAY_PRIVACY_POLICY = 'appData/privacy/SET_DISPLAY_PRIVACY_POLICY',
  SELECT_PRIVACY_POLICY = 'appData/privacy/SELECT_PRIVACY_POLICY',
  SELECT_PURPOSE = 'appData/privacy/SELECT_PURPOSE',
  PRIVACY_POLICY_UPDATE_REQUEST = 'appData/privacy/PRIVACY_POLICY_UPDATE_REQUEST',
  PRIVACY_POLICY_UPDATE_SUCCESS = 'appData/privacy/PRIVACY_POLICY_UPDATE_SUCCESS',
  PRIVACY_POLICY_UPDATE_FAILURE = 'appData/privacy/PRIVACY_POLICY_UPDATE_FAILURE',
  PRIVACY_POLICY_DELETE_REQUEST = 'appData/privacy/PRIVACY_POLICY_DELETE_REQUEST',
  PRIVACY_POLICY_DELETE_SUCCESS = 'appData/privacy/PRIVACY_POLICY_DELETE_SUCCESS',
  PRIVACY_POLICY_DELETE_FAILURE = 'appData/privacy/PRIVACY_POLICY_DELETE_FAILURE',
  PURPOSE_UPDATE_REQUEST = 'appData/privacy/PURPOSE_UPDATE_REQUEST',
  PURPOSE_UPDATE_SUCCESS = 'appData/privacy/PURPOSE_UPDATE_SUCCESS',
  PURPOSE_UPDATE_FAILURE = 'appData/privacy/PURPOSE_UPDATE_FAILURE',
}
