import React from 'react';
import ListEditItem from '../../../shared/ListEditItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import { renderDate } from '../../../shared/TableLayout';
import styles from '../UserInfo.styles';

interface UserIdentityInfoProps {
  selectedUser: any;
  intl: any;
  onEditIdentity: () => void;
  onTitleUpdate: () => void;
}

const UserIdentityInfo: React.FC<UserIdentityInfoProps> = ({
  selectedUser,
  intl,
  onEditIdentity,
  onTitleUpdate,
}) => {
  const formattedTitle = selectedUser?.title
    ? intl.formatMessage({ id: `fleetadmin.users.info.${selectedUser.title}` })
    : null;

  return (
    <>
      <div className="user-section-title" style={{ marginTop: 10 }}>
        {intl.formatMessage({ id: `fleetadmin.users.info.identity` })}
      </div>
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.firstname` })}
        leftItem={renderText(selectedUser.firstName, false)}
        verified={selectedUser?.firstNameVerified}
        onClickEdit={onEditIdentity}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.lastname` })}
        leftItem={renderText(selectedUser.lastName, false)}
        verified={selectedUser?.lastNameVerified}
        onClickEdit={onEditIdentity}
      />
      {selectedUser?.legalFirstName && (
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.users.info.legalfirstname`,
          })}
          leftItem={renderText(selectedUser?.legalFirstName, false)}
          verified
          onClickEdit={onEditIdentity}
        />
      )}
      {selectedUser?.legalLastName && (
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.users.info.legallastname`,
          })}
          leftItem={renderText(selectedUser?.legalLastName, false)}
          verified
          onClickEdit={onEditIdentity}
        />
      )}
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.birthday` })}
        leftItem={renderDate(selectedUser.dateOfBirth, false)}
        verified={selectedUser?.dateOfBirthVerified}
        onClickEdit={onEditIdentity}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.birthplace` })}
        leftItem={renderText(selectedUser.birthPlace, false)}
        verified={selectedUser?.birthPlaceVerified}
        onClickEdit={onEditIdentity}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.title` })}
        leftItem={renderText(formattedTitle, false)}
        onClickEdit={onTitleUpdate}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default UserIdentityInfo;
