import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../../../components/shared/DrawerModal';
import styles from './PolicyStatusModal.styles';
import {
  getIsVisibleModal,
  closeModal,
} from '../../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import SelectorLayout from '../../../shared/SelectorLayout';
import {
  archivePrivacyPolicy,
  publishPrivacyPolicy,
  selectSelectedPrivacyPolicy,
} from '../../../../redux/store/appData/privacyPolicy';

type State = {
  nextStatus: string | undefined;
};

const POLICY_STATUS_OPTIONS = ['PUBLISHED', 'ARCHIVED'];

const PolicyStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedPolicy = useSelector(selectSelectedPrivacyPolicy);

  const initialState = {
    nextStatus: selectedPolicy?.status,
  };
  const [state, setState] = useState<State>(initialState);
  const { nextStatus } = state;
  const visibleModal = useSelector(getIsVisibleModal('PolicyStatusModal'));
  const currentStatus = selectedPolicy?.status;

  const statusOptions = POLICY_STATUS_OPTIONS.map((option: any) => ({
    label: intl.formatMessage({
      id: `fleetadmin.status.${option}`,
    }),
    value: option,
  }));

  const onSelectUserStatus = (value: any) => {
    setState({ ...state, nextStatus: value });
  };

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (nextStatus === 'PUBLISHED') {
      dispatch(publishPrivacyPolicy(selectedPolicy.id));
    } else if (nextStatus === 'ARCHIVED') {
      dispatch(archivePrivacyPolicy(selectedPolicy.id));
    }
    dispatch(closeModal('PolicyStatusModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('PolicyStatusModal'));
  };

  useEffect(() => {
    setState(initialState);
  }, [visibleModal, selectedPolicy]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('PolicyStatusModal'));
        }}
      >
        <div className="policy-status-modal">
          <div className="status-selector-wrapper">
            <p className="label">Status</p>
            <SelectorLayout
              keyPrefix="care-search"
              placeholder={intl.formatMessage({
                id: 'fleetadmin.policy.status.placeholder',
              })}
              options={statusOptions}
              value={nextStatus}
              onSelect={onSelectUserStatus}
            />
          </div>

          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={currentStatus === nextStatus}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default PolicyStatusModal;
